import store from "./store";

const pathApp = {
  "saber-admin": {
    pro: window.location.origin + "/saber-micro-app/",
    dev: "//localhost:8080",
  },
  "workflow-micro": {
    pro: window.location.origin + "/workflow-micro-app/",
    dev: "//localhost:8008",
  },
  "notify-micro": {
    pro: window.location.origin + "/notify-micro-app/",
    dev: "//localhost:8009",
  },
  "miniApp-micro": {
    pro: window.location.origin + "/miniApp-micro-app/",
    dev: "//localhost:8011",
  },
  "cmdb-micro": {
    pro: window.location.origin + "/cmdb-micro-app/",
    dev: "//localhost:8010",
  },
  "project-micro": {
    pro: window.location.origin + "/project-micro-app/",
    dev: "//localhost:8086",
  },
  "license-micro": {
    pro: window.location.origin + "/license-micro-app/",
    dev: "//localhost:1888",
  },
};

function genActiveRule(routerPrefix) {
  return (location) => location.pathname.startsWith(routerPrefix);
}
const microApps = [];
Object.keys(pathApp).map((key) => {
  const item = {
    name: key,
    entry:
      process.env.NODE_ENV !== "development"
        ? pathApp[key].pro
        : pathApp[key].dev,
    container: "#subapp-viewport",
    activeRule: genActiveRule(`/${key}`),
  };
  microApps.push(item);
});
const apps = microApps.map((item) => {
  return {
    ...item,
    props: {
      routerBase: item.activeRule, // 下发基础路由
      getGlobalState: store.getGlobalState, // 下发getGlobalState方法
    },
  };
});

export default apps;
