import { initGlobalState } from "qiankun";
import Vue from "vue";
const keyName = "saber-";

export const getStore = (params = {}) => {
  let { name, debug } = params;
  name = keyName + name;
  let obj = {},
    content;
  obj = window.sessionStorage.getItem(name);
  if (validatenull(obj)) obj = window.localStorage.getItem(name);
  if (validatenull(obj)) return;
  try {
    obj = JSON.parse(obj);
  } catch {
    return obj;
  }
  if (debug) {
    return obj;
  }
  if (obj.dataType == "string") {
    content = obj.content;
  } else if (obj.dataType == "number") {
    content = Number(obj.content);
  } else if (obj.dataType == "boolean") {
    content = eval(obj.content);
  } else if (obj.dataType == "object") {
    content = obj.content;
  }
  return content;
};
/**
 * 判断是否为空
 */
export function validatenull(val) {
  if (typeof val === "boolean") {
    return false;
  }
  if (typeof val === "number") {
    return false;
  }
  if (val instanceof Array) {
    if (val.length == 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === "{}") return true;
  } else {
    if (
      val == "null" ||
      val == null ||
      val == "undefined" ||
      val == undefined ||
      val == ""
    )
      return true;
    return false;
  }
  return false;
}
// 父应用的初始state
// Vue.observable是为了让initialState变成可响应：https://cn.vuejs.org/v2/api/#Vue-observable。
const storeConfig_v2 = localStorage.getItem("storeConfig_v2");
let isDark = false;
if (storeConfig_v2) {
  const storeConfig_v21 = JSON.parse(storeConfig_v2);
  isDark = storeConfig_v21.layout?.isDark || false;
  console.log("🚀 ~ isDark:2222222222222", isDark);
}
if (!isDark) {
  isDark = getStore({ name: "isDark" }) || false;
}

// const isDark = getStore({ name: "isDark" }) || storeConfig_v2;
const initialState = Vue.observable({
  /**执行方法名称 */
  fun: "",
  /**是否打开左侧菜单 */
  openFirstFun: "close",
  /**函数参数 */
  args: [],
  /**执行文件名称 */
  executeFile: "",
  keyCollapse: false,
  isCollapse: false,
  isLoginPage: true,
  /** 左侧菜单 */
  leftMenu: [],
  /** 左侧菜单给谁 */
  lmMicro: "",
  /** 顶部菜单 */
  topMenu: [],
  /** 当前顶部菜单 */
  topMenuItem: [],
  tagvalue: "",
  /** 工作流路由 */
  workRouter: [],
  /** 当前标签 */
  addTag: null,
  /** 需要删除的标签 */
  delTag: null,
  /** Link标签 */
  link: [],
  /** 设置黑暗模式 */
  setDark: isDark,
});

const actions = initGlobalState(initialState);

actions.onGlobalStateChange((newState, prev) => {
  // state: 变更后的状态; prev 变更前的状态
  console.log("main change", JSON.stringify(newState), JSON.stringify(prev));

  for (const key in newState) {
    initialState[key] = newState[key];
  }
});

// 定义一个获取state的方法下发到子应用
actions.getGlobalState = (key) => {
  // 有key，表示取globalState下的某个子级对象
  // 无key，表示取全部

  return key ? initialState[key] : initialState;
};

export default actions;
